<template>
  <v-dialog
    v-model="isModalOpen"
    @click:outside="toggleModal"
    width="850"
  >
    <v-card v-if="currentItem">
      <v-toolbar
        class="py-2"
        flat
      >
        <v-toolbar-title>
          <h2 class="font-weight-regular text-h3 ml-6">
            {{ $t("stockManagements.inbound_stock_details") }}
          </h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          class="mr-0"
        >
          <v-icon @click="close()">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="">
        <div class="d-flex flex-row mx-4">
          <v-img
            class="me-4"
            max-height="200"
            max-width="200"
            v-if="currentItem?.image"
            :src="currentItem?.image?.url"
          />
          <v-img
            v-else
            class="me-4"
            src="@/assets/product.png"
            max-height="100"
            max-width="100"
          />
          <div class="">
            <p class="ma-1 font-weight-regular">{{ currentItem?.name }}</p>

            <p class="text--disabled mx-1 body-1">
              ID: {{ currentItem.generated_code }} . Barcode :
              {{ currentItem.barcode }}
            </p>

            <p class="mt-4 mb-0 ml-1 font-weight-400 red--text text--darken-1">
              {{ ordered_qte ?? 0 }} {{ $t("ordered_qte") }}
            </p>
            <p
              class="mt-2 mb-0 ml-1 font-weight-400 green--text text--darken-1"
            >
              {{ received_qte ?? 0 }} {{ $t("received_qte") }}
            </p>
          </div>
          <div class="ml-auto">{{ currentItem.date }}</div>
        </div>

        <div class="mt-9 mx-2">
          <v-data-table
            :headers="headers"
            :items="currentItem.orders"
            :items-per-page="5"
            class="elevation-0"
          >
          </v-data-table>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isModalOpen: Boolean,
    currentItem: Object,
    toggleModal: Function,
  },
  mounted: function () {},
  computed: {
    ordered_qte() {
      return this.currentItem?.orders
        ?.map((item) => item.ordered_quantity)
        .reduce((a, b) => a + b, 0);
    },
    received_qte() {
      return this.currentItem?.orders
        ?.map((item) => item.received_quantity)
        .reduce((a, b) => a + b, 0);
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("tracking_number"),
          align: "start",
          sortable: true,
          value: "tracking_number",
        },
        {
          text: this.$t("stockManagements.from_supplier"),
          align: "start",
          sortable: true,
          value: "provider_name",
        },
        {
          text: this.$t("hub"),
          align: "start",
          sortable: true,
          value: "hub_name",
        },
        {
          text: this.$t("stockManagements.qte_ordered"),
          align: "center",
          sortable: true,
          value: "ordered_quantity",
        },
        {
          text: this.$t("stockManagements.qte_received"),
          align: "center",
          sortable: true,
          value: "received_quantity",
        },
      ],
    };
  },

  methods: {
    close() {
      this.toggleModal();
    },

    getColor(status) {
      if (status === "Delivered") return "green";
      else return "red";
    },
  },
};
</script>

<style scoped>
p {
  line-height: 1;
}
</style>
